<template>
  <v-card>
    <v-list-item-content>
      <v-card-title class="headline mx-auto"
        >Lançamento financeiro
      </v-card-title>

      <base-loading v-if="loading.loading" />
      <v-form v-else id="form" ref="form" v-model="valid">
        <v-card class="mx-2 my-2">
          <v-card-title class="font-weight-medium text-h4"
            >Dados do lançamento
          </v-card-title>
          <v-row v-if="!loading.loading" class="pa-3">
            <v-col
              v-if="
                $user.get().role === 'admin' ||
                $user.get().role === 'consultoria'
              "
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="form.userId"
                :items="list.consultants"
                label="Consultores *"
                item-text="Nome"
                item-value="user_id"
                hint="Selecione um consultor"
                required
                :rules="rules.requiredRules"
                :loading="loading.consultants"
                :disabled="loading.consultants"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4" sm="4">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="30"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="moment(form.launch).format('DD/MM/YYYY')"
                    color="teal"
                    label="Data do lançamento *"
                    prepend-icon="mdi-calendar"
                    readonly
                    :rules="rules.requiredRules"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="form.launch"
                  name="date"
                  color="teal"
                  locale="pt-br"
                  style="margin-top: 0; margin-bottom: 0"
                  :max="maxDate"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mx-2 my-2">
          <v-card-title class="font-weight-medium text-h4"
            >Depesas do consultor
          </v-card-title>
          <v-row v-if="!loading.loading" class="pa-3">
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.combustivel"
                name="fuelPrice"
                label="Combustível (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.hospedagem"
                name="accommodation"
                label="Hospedagem (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.alimentacao"
                name="food"
                label="Alimentação (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.pedagio"
                name="toll"
                label="Pedágio (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.fees"
                name="fees"
                label="Honorários (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.healthInsurance"
                name="healthInsurance"
                label="Plano de Saúde (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.lifeInsurance"
                name="lifeInsurance"
                label="Seguro de Vida (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.otherValues"
                name="otherValues"
                label="Outros valores (R$)"
                :options="options"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="form.otherValuesDescription"
                label="Outros valores (Descrição)"
                hint="Descreva o lançamento"
              />
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mx-2 my-2">
          <v-card-title class="font-weight-medium text-h4"
            >Debitos do consultor
          </v-card-title>
          <v-row v-if="!loading.loading" class="pa-3">
            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.unitedBreakdowns"
                class="withdraw"
                name="unitedBreakdowns"
                label="Avarias Aluguel de Carro (R$)"
                :options="options"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.fineLateData"
                class="withdraw"
                name="fineLateData"
                label="Multa Atraso Envio dos dados (R$)"
                :options="options"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.driverFines"
                class="withdraw"
                name="driverFines"
                label="Multas Condutor (R$)"
                :options="options"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.postgraduate"
                class="withdraw"
                name="Postgraduate"
                label="Pós Graduação (R$)"
                :options="options"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.purchasing"
                class="withdraw"
                name="purchasing"
                label="Compras/Aquisição (R$)"
                :options="options"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <vuetify-money
                v-model="form.otherDiscounts"
                class="withdraw"
                name="otherDiscounts"
                label="Outros descontos (R$)"
                :options="options"
              />
            </v-col>

            <v-col cols="6" sm="6">
              <v-text-field
                v-model="form.otherDiscountsDescription"
                label="Descontos (Descrição)"
                hint="Descreva a despesa"
              />
            </v-col>
          </v-row>
        </v-card>

        <v-col cols="12" sm="12" align="right">
          <v-btn
            style="color: white"
            color="green"
            :loading="save"
            @click="sendForm"
          >
            <span>Salvar</span>
          </v-btn>

          <router-link
            :disabled="save"
            :to="`/${$user.get().role}/financeiros`"
          >
            <v-btn :disabled="save" color="error" class="ml-3">
              Cancelar
            </v-btn>
          </router-link>
        </v-col>
      </v-form>
    </v-list-item-content>
  </v-card>
</template>

<script>
import consultantServices from '../../../services/api/consultant/index'
import financialReleaseService from '../../../services/api/financial'
export default {
  name: 'Form',

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: {
        consultants: false,
        loading: false,
      },
      save: false,

      rules: {
        launch: [v => !!v || 'Data do lançamento é obrigatório'],
        requiredRules: [v => !!v || '* Obrigatório'],
      },

      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 11,
        precision: 2,
      },

      maxDate: new Date().toISOString().substr(0, 10),

      form: {
        userId: 0,
        combustivel: 0,
        alimentacao: 0,
        hospedagem: 0,
        pedagio: 0,
        fees: 0,
        healthInsurance: 0,
        lifeInsurance: 0,
        otherValues: 0,
        otherValuesDescription: '',
        unitedBreakdowns: 0,
        fineLateData: 0,
        driverFines: 0,
        postgraduate: 0,
        purchasing: 0,
        otherDiscounts: 0,
        otherDiscountsDescription: '',
        launch: this.moment().toISOString(true).substr(0, 10),
      },

      list: {
        consultants: [],
      },

      valid: false,
    }
  },

  created() {
    if (this.id) {
      this.getFinanceiro(this.id)
    }

    if (
      this.$user.get().role === 'admin' ||
      this.$user.get().role === 'consultoria'
    ) {
      this.getConsultores()
    }
  },

  methods: {
    async getFinanceiro(id) {
      this.loading.loading = true
      const result = await consultantServices.financeiro.find({ id })

      if (result.success) {
        this.form = {
          ...result.data,
          userId: String(result.data.userId),
          launch: this.moment(result.data.launch)
            .toISOString(true)
            .substr(0, 10),
        }
      } else {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao buscar lançamento financeiro: ' + result.message,
        })
      }

      this.loading.loading = false
    },

    async sendForm() {
      this.valid = await this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios',
        })
      }

      if (this.id) {
        await this.update()
      } else {
        await this.create()
      }
    },

    async create() {
      this.save = true

      try {
        const response = await financialReleaseService.create({
          form: this.form,
        })

        if (response.success) {
          this.Toast().fire({
            icon: 'success',
            title: 'Lançamento financeiro realizado com sucesso',
          })
          this.$router.push({
            path: `/${this.$user.get().role}/financeiros`,
          })
        } else {
          this.Swal.fire(
            'Erro ao realizar lançamento financeiro',
            response.message,
            'error',
          )
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao realizar lançamento financeiro: ' + error.message,
        })
      } finally {
        this.save = false
      }
    },

    async update() {
      this.save = true

      try {
        const response = await financialReleaseService.update({
          id: this.id,
          form: this.form,
        })

        if (response.success) {
          this.Toast().fire({
            icon: 'success',
            title: 'Lançamento financeiro atualizado com sucesso',
          })
          this.$router.push({
            path: `/${this.$user.get().role}/financeiros`,
          })
        } else {
          this.Toast().fire({
            icon: 'error',
            title:
              'Erro ao atualizar lançamento financeiro: ' + response.message,
          })
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar lançamento financeiro: ' + error.message,
        })
      } finally {
        this.save = false
      }
    },

    async getConsultores() {
      this.loading.consultants = true
      const result = await consultantServices.list()

      this.list.consultants = result
      this.loading.consultants = false
    },
  },
}
</script>

<style>
.withdraw {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  color: #ff715b;
}

.withdraw .v-text-field__slot {
  color: #ff715b;
}

.withdraw .v-text-field__slot > input {
  color: #ff715b;
}
</style>
