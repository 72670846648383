<template>
  <div>
    <div class="px-10">
      <Form :id="id" />
    </div>
  </div>
</template>

<script>
import Form from './form.vue'

export default {
  components: {
    Form,
  },

  data() {
    return {
      loading: {
        visita: false,
      },
      visita: null,
      id: null,
    }
  },

  async created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
  },

  methods: {},
}
</script>

<style>
.v-tab--active .v-icon {
  color: #003677 !important;
}
</style>
