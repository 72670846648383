import api from '../../../api/api'

export default {
  async all() {
    const response = await api.get.allFinacial()
    return response
  },

  async list({ DataInicio, DataFim, id = null }) {
    const response = await api.get.reportData({ DataInicio, DataFim, id })
    return response
  },

  async create({ form }) {
    const response = await api.save.financialRelease(form)
    return response
  },

  async update({ id, form }) {
    const response = await api.update.financialRelease(id, form)
    return response
  },

  async delete({ id }) {
    const response = await api.delete.financialRelease(id)
    return response
  },
}
